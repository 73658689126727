import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-use'

const Transition = ({ children }) => {
    const location = useLocation()
	
	const variants = {
        initial: {
			opacity: 0,	
        },
        animate: {
			opacity: 1,
            transition: {
				duration: 0.6,
				delay: 0.6,
                ease: 'easeInOut',
            },
        },
        exit: {
			opacity: 0,
        },
    }

    return (
        <AnimatePresence>
            <Wrapper key={location.pathname} {...variants}>
                {children}
            </Wrapper>
        </AnimatePresence>
    )
}

const Wrapper = styled(motion.div)`
    width: 100%;
    display: flex;
    justify-content: center;
	opacity: 0;
`

export default Transition
