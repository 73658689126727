import { css, createGlobalStyle } from 'styled-components'
import { media } from './utils'

import globalType from './type'
import globalColours from './colours'

export const GlobalStyles = createGlobalStyle`
    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        background: white;
		color: ${globalColours.blue};
		font-family: 'Helvetica', sans-serif;
		font-weight: 500;
	}
	
	/* width */
	::-webkit-scrollbar {
		width: 3px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: transparent;
	}

	/* Handle */
	::-webkit-scrollbar-thumb {
		background: ${globalColours.blue};
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: ${globalColours.blue};
	}
    
    a {
		text-decoration: none;
		font-weight: 500;
		
		&:visited {
			color: inherit;
		}
    }

    h1, h2, h3 {
        font-size: 1rem;
		margin: 0;
		font-weight: 500;
    }

    a, p, div {
        ${globalType.body};
        letter-spacing: 0.15px;
        font-weight: 500;
    }

    b, strong {
        font-weight: 500;
    }

    * {
        -webkit-overflow-scrolling: touch;
        -webkit-font-smoothing: antialiased;
        box-sizing: border-box;
    }

    p:first-child {
		margin-top: 0;
	}

    /* Next Wrapper */

    #__next {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
	}
	
	@keyframes clockwise {
		0% {
			transform: rotateY(0deg);
		}
		
		100% {
			transform: rotateY(360deg);
		}

	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}
		
		100% {
			transform: rotateY(360deg);
		}

	}
`

export const wrapper = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    height: 100%;
`

export const container = css`
    display: flex;
    width: 100%;
`

export const hoverState = css`
    transition: 0.15s opacity ease;

    &:hover {
        opacity: 0.7;
    }
`

export const padding = css`
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;

    ${media.tablet`
		padding-left: 20px;
		padding-right: 20px;
	`}
`

export const bgImage = css`
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
`

export const bgIcon = css`
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
`

// Re-export colours & type

export const colours = globalColours
export const type = globalType
