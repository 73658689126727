import { css } from 'styled-components'
import { media } from '../styles/utils'

const type = {
    heading1: css`
        font-size: 172px;
		line-height: 1.1;
		font-weight: 500;

        ${media.phone`
			font-size: 72px;
		`}
    `,
    heading2: css`
        font-size: 132px;
		line-height: 1.1;
		font-weight: 500;

        ${media.phone`
			font-size: 72px;
		`}
    `,
    heading3: css``,
    heading4: css``,
    body: css`
        font-size: 20px;
		line-height: 24px;
		font-weight: 500;

        ${media.phone`
			font-size: 18px;
		`}
    `,
}

export default type
