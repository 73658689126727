import React from 'react'
import Transition from './src/transition'
import { GlobalStyles } from './src/styles/global'
import { PortalProvider } from 'react-portal-hook'

export default ({ element, props }) => {
    return (
        <>
            <GlobalStyles />
            <PortalProvider>{element}</PortalProvider>
        </>
    )
}
